import _ from 'lodash'

# 
# GtCommonComponent ( 1.0.0 )
# 
class GtCommonComponent
  constructor: ->
    @core = {}
    @ui = {}
    @status = {}
    @setting = {}

    if @validateRoute()
      @initOptions()
      @initDom()

  initOptions: ->
    # ignore

  initDom: ->
    new GtDomEventSupport
      component: this
      mount: => @mount()

  name: ->
    'no_name'

  route: ->
    true

  validateRoute: ->
    rule = @route()
    
    if rule is true
      true
    else if _.isString(rule)
      window.location.pathname.indexOf(rule) > -1
    else if _.isRegExp(rule)
      rule.test(window.location.pathname)
    else
      false

  debug: ->
    if window.debugMode? then window.debugMode else no

  mount: ->
    yes

  destroy: ->
    yes


window.GtCommonComponent = GtCommonComponent
