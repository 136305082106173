import { TimelineMax, Power2 } from 'gsap'

# 
# NewsArticlesIndexPage ( 0.1.0.0 )
# 
class NewsArticlesIndexPage extends window.GtCommonComponent
  name: ->
    'news_articles_index_page'

  initOptions: ->
    # ignore

  fetchUI: ->

    @ui.main = $('body.news_articles.index')
    @ui.articles = @ui.main.find('.article')

  checkUI: ->
    @fetchUI()

    result =
      @ui.main.length is 1 &&
      @ui.articles.length > 0

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initUI: ->
    @core.preloadSupport =
      new GtListImagesPreloadSupport
        currentPageIndex: 0
        perPage: 9999
        items: @ui.articles
        delay: 100
        breakpoint: => yes
        done: (metaData) =>
          @showItem(metaData)

    @core.preloadSupport.start()

  showItem: (metaData) ->
    TweenMax.set metaData.ui.item, { y: -15, opacity: 0 }
    metaData.ui.img.attr 'src', metaData.preloadUrl

    TweenMax.to metaData.ui.item, 2, {
      y: 0, opacity: 1, ease: Power2.easeOut, delay: 0.1
    }

window.NewsArticlesIndexPage = new NewsArticlesIndexPage