# 
# GtCommandQueue ( 1.2.6 base CommandQueue )
#
class GtCommandQueue
  constructor: ->
    @objectName = 'CommandQueue'
    @index      = 0
    @count      = 0
    @queue      = []
    @autoMode   = no
    @time       = 0
    @status     = {}

    @initCore()

  initCore: ->
    @status.forceStop = no

  add: (callback) ->
    @queue.push(callback)

  restart: ->
    @index = 0
    @start()

  stop: ->
    @status.forceStop = yes
    @index = 0

  start: (options = {}) ->
    @status.forceStop = no if options.reset

    if options.auto is yes
      @autoMode = yes
      @time = options.time

    @count = @queue.length
    @runNextCommand() if @count > 0

  runNextCommand: () ->
    unless @status.forceStop
      @queue[@index]()
      @callNext(@time) if @autoMode is yes

  callNext: (waitMilliseconds = 0)->
    run = =>
      @index += 1
      @runNextCommand() if @index < @count

    if waitMilliseconds is 0
      run()
    else
      setTimeout run, waitMilliseconds

  clean: ->
    @index = 0
    @count = 0
    @queue = []

window.GtCommandQueue = GtCommandQueue
