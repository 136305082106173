import _ from 'lodash'
import { TimelineMax, Power1 } from 'gsap'

# 
# DynamicOrnaments ( 0.1.1.0 )
# 
class DynamicOrnaments extends window.GtCommonComponent
  name: ->
    'dynamic_ornaments'

  initOptions: ->
    @core.autoNextTimer = null
    @core.queue = new GtCommandQueue
    @core.sectionCaches = []

    @setting.defaultPagination = 1

    @status.numberOfSection = null
    @status.currentSectionNumber = @setting.defaultPagination
    @status.previousSectionNumber = null

    @status.showFirstSection = false

  fetchUI: ->
    @ui.main = $('.dynamic-ornaments')
    @ui.sections = @ui.main.find('div')

  checkUI: ->
    @fetchUI()

    result = @ui.main.length is 1 &&
             @ui.sections.length > 0

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initUI: ->
    @cacheSections()
    @initSections()

  cacheSections: ->
    @calculateSectionCount()

    @ui.sections.each (index, eleSection) =>
      @core.sectionCaches.push $(eleSection)

  initSections: ->
    @ui.sections.each (index, eleSection) =>
      uiSection = $(eleSection)
      @core.queue.add => @loadSection uiSection
    
    @core.queue.start()

  fetchUiSectionImageUrl: (ui) ->
    if PageControl.isNoNarrowLayout()
      ui.data('image')
    else
      if _.isString(ui.data('mobile')) && ui.data('mobile').length > 0
        ui.data('mobile')
      else
        ui.data('image')

  loadSection: (uiSection) ->
    imageUrl = @fetchUiSectionImageUrl(uiSection)

    if _.isEmpty(imageUrl)
      @core.queue.callNext 1000
      return

    buffer = new Image

    buffer.onerror = =>
      @core.queue.callNext 1000

    buffer.onload = =>
      @core.queue.callNext 1000
      TweenMax.set uiSection, { opacity: 0, backgroundImage: "url(#{imageUrl})" }

      if @status.showFirstSection isnt yes
        @switchSection()
        @status.showFirstSection = yes

    buffer.src = imageUrl

  calculateSectionCount: ->
    @status.numberOfSection = @ui.sections.length

  calculatePreviousSectionNumber: ->
    @status.previousSectionNumber = @status.currentSectionNumber

  next: ->
    @calculatePreviousSectionNumber()

    if @status.currentSectionNumber is @status.numberOfSection
      @zapTo 1
    else
      @zapTo @status.currentSectionNumber + 1

  zapTo: (sectionNum) ->
    @status.currentSectionNumber = sectionNum
    @switchSection()

  getSectionElementBySectionNumber: (sectionNum) ->
    @core.sectionCaches[sectionNum - 1]

  switchSection: ->
    clearTimeout @core.autoNextTimer

    uiCurrentSection  = @getSectionElementBySectionNumber(@status.currentSectionNumber)
    uiPreviousSection = @getSectionElementBySectionNumber(@status.previousSectionNumber)

    if uiCurrentSection?
      TweenMax.set uiCurrentSection, { opacity: 0 }
      TweenMax.to uiCurrentSection, 1, { opacity: 1, ease: Power1.easeOut }

    if uiPreviousSection?
      TweenMax.to uiPreviousSection, 1, { opacity: 0, ease: Power1.easeOut }

    @autoNext() if @core.sectionCaches.length > 1

  autoNext: ->
    @core.autoNextTimer = setTimeout =>
      @next()
    , 8000

window.DynamicOrnaments = new DynamicOrnaments