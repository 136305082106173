import { TimelineMax, Power1 } from 'gsap'

# 
# AboutPage ( 0.1.0.0 )
# 
class AboutPage extends window.GtCommonComponent
  name: ->
    'about_page'

  initOptions: ->
    @core.animations = {}

  fetchUI: ->

    @ui.main = $('body.pages.about')
    @ui.mainInterface = @ui.main.find('.main-interface')

  checkUI: ->
    @fetchUI()

    result =
      @ui.main.length is 1 &&
      @ui.mainInterface.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initAnimation: ->
    tl = new TimelineMax paused: yes

    tl.add 'start'
    tl.set @ui.mainInterface, { opacity: 0 }

    tl.add 'fadeIn'
    tl.to @ui.mainInterface, 0.5, { opacity: 1, ease: Power1.easeOut }, "fadeIn"

    @core.animations.main = tl

  initUI: ->
    @initAnimation()
    @core.animations.main.play 0

window.AboutPage = new AboutPage