# 
# GtSimplePreload ( 1.1.0 )
# 
class GtSimplePreload
  constructor: (options={}) ->
    options.url  = ''     if not options.url?
    options.done = ( -> ) if not options.done?

    @options = options

    @start()

  start: ->
    buffer = new Image()

    buffer.onload  = =>
      aspectRatio = ( buffer.height / buffer.width ) || 0
      @options.done(buffer.width, buffer.height, aspectRatio)

    buffer.onerror = =>
      @options.done(0, 0, 0)

    buffer.src = @options.url

window.GtSimplePreload = GtSimplePreload