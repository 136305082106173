import { TimelineMax, Power1, Power2 } from 'gsap'

# 
# HomePage ( 0.1.0.0 )
# 
class HomePage extends window.GtCommonComponent
  name: ->
    'home_page'

  initOptions: ->
    @core.animations = {}

  fetchUI: ->
    @ui.main = $('body.pages.home')
    @ui.welcomeMessage = $('.welcome-message')
    @ui.welcomeMessageLogo = @ui.welcomeMessage.find('.logo')

  checkUI: ->
    @fetchUI()

    result =
      @ui.main.length is 1 &&
      @ui.welcomeMessage.length is 1 &&
      @ui.welcomeMessageLogo.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initAnimation: ->
    tl = new TimelineMax paused: yes

    tl.add 'start'
    tl.set @ui.welcomeMessage, { opacity: 1 }
    tl.set @ui.welcomeMessageLogo, { opacity: 0, y: -5 }

    tl.add 'fadeIn'
    tl.to @ui.welcomeMessageLogo, 2, { opacity: 1, y: 0, ease: Power1.easeOut }, 'fadeIn'

    tl.add 'fadeOut'
    tl.to @ui.welcomeMessage, 0.75, { opacity: 0, ease: Power2.easeOut, display: 'none' }

    @core.animations.welcome = tl

  initUI: ->
    @initAnimation()
    @core.animations.welcome.play 0

window.HomePage = new HomePage