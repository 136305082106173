# 
# PageIndexManager ( 0.9.1 )
# 
class PageIndexManager

  getCurrentPage: (options={})->
    path = options.path || @currentPagePath()

    try
      parseInt(window.localStorage.getItem("pim:#{path}") || 0, 10)
    catch e
      0

  setCurrentPage: (options={})->
    path = options.path || @currentPagePath()
    index = options.index || 0

    try
      window.localStorage.setItem("pim:#{path}", index)
    catch e
      # ignore

  currentPagePath: ->
    window.location.pathname

  reset: ->
    window.localStorage.clear()
  
window.PageIndexManager = new PageIndexManager