import _ from 'lodash'

# 
# GtListImagesPreloadSupport ( 1.2.0.0 )
# 
class GtListImagesPreloadSupport
  constructor: (options={}) ->
    @core    = {}
    @ui      = {}
    @options = null

    @initOptions(options)
    @initCore()
    @startProcess()

  initOptions: (options) ->
    options.items = null            unless options.items?
    options.delay = 100             unless options.delay?
    options.done  = ( -> )          unless options.done?
    options.breakpoint = true       unless options.breakpoint?
    options.imgSelector = 'img'     unless options.imgSelector?
    options.currentPageIndex = 0    unless options.currentPageIndex?
    options.perPage = 1             unless options.perPage?

    @options = options

  initCore: ->
    @core.metaInfo = []
    @core.preloadQueue = new GtCommandQueue
    @core.metaInfoLength = 0

    @ui.items = @options.items

  startProcess: ->
    @collectItemsMetaData()
    @reallocateItemsMetaData()
    @createPreloadQueue()

  createPreloadQueue: ->
    _.each @core.metaInfo, (metaData) =>
      if metaData.imageIsFound
        @core.preloadQueue.add =>
          new GtSimplePreload
            url: metaData.preloadUrl
            done: (width, height, aspectRatio) =>
              imageInfo = {
                width: width
                height: height
                aspectRatio: aspectRatio
              }

              @options.done metaData, imageInfo
              @core.preloadQueue.callNext @options.delay
      else
        @core.preloadQueue.add =>
          @options.done metaData 
          @core.preloadQueue.callNext @options.delay

  collectItemsMetaData: ->
    return if @options.items? isnt yes

    @ui.items.each (index, elementItem) =>
      @createMetaData $(elementItem)

    @core.metaInfoLength = @core.metaInfo.length

  reallocateItemsMetaData: ->
    return if @core.metaInfoLength < 1
    return if @core.metaInfoLength <= @options.perPage
    return if @options.currentPageIndex is 0

    metaInfoGroups = _.chunk(@core.metaInfo, @options.perPage)

    lastGroupIndex = Math.ceil(@core.metaInfoLength / @options.perPage) - 1
    inLastPage = lastGroupIndex == @options.currentPageIndex

    currentGroup = metaInfoGroups[@options.currentPageIndex]
 
    if inLastPage
      secondGroup = metaInfoGroups[@options.currentPageIndex - 1]
      thirdGroup  = metaInfoGroups[@options.currentPageIndex - 2]
    else
      secondGroup = metaInfoGroups[@options.currentPageIndex + 1]
      thirdGroup  = metaInfoGroups[@options.currentPageIndex - 1]

    pickedGroups = []
    pickedGroups.push currentGroup
    pickedGroups.push secondGroup  if secondGroup? 
    pickedGroups.push thirdGroup   if thirdGroup?

    otherGroups = _.difference(metaInfoGroups, pickedGroups)
    otherGroups = _.shuffle(otherGroups)

    newGroups = pickedGroups.concat(otherGroups)
    @core.metaInfo = _.flatten(newGroups)

  createMetaData: (uiItem) ->
    meta = {
      ui: { item: uiItem, img: uiItem.find(@options.imgSelector) }
      imageIsFound: null
      preloadUrl: null
      thumbnailUrl: null
      mobileThumbnailUrl: null
    }

    meta.imageIsFound = meta.ui.img.length > 0

    if meta.imageIsFound
      meta.thumbnailUrl       = meta.ui.img.data('thumbnail') || ''
      meta.mobileThumbnailUrl = meta.ui.img.data('mobile') || ''
      
      if meta.mobileThumbnailUrl.length is 0
        meta.mobileThumbnailUrl = meta.thumbnailUrl

      if @layoutBreakpoint()
        meta.preloadUrl = meta.thumbnailUrl
      else
        meta.preloadUrl = meta.mobileThumbnailUrl

    @core.metaInfo.push meta

  layoutBreakpoint: ->
    @options.breakpoint()

  start: ->
    @core.preloadQueue.start()

  destroy: ->
    @core.preloadQueue.stop()
    @core.preloadQueue = []
    @core.metaInfo = []

window.GtListImagesPreloadSupport = GtListImagesPreloadSupport
