import _ from 'lodash'

# 
# MainMenu ( 0.1.0.0 )
# 
class MainMenu extends window.GtCommonComponent
  name: ->
    'main_menu'

  initOptions: ->
    @core.animations = {}

    @status.avaliable = no

  fetchUI: ->
    @ui.main = $('.main-menu')
    @ui.links = @ui.main.find('.links a')

  checkUI: ->
    @fetchUI()

    result = @ui.main.length is 1 &&
             @ui.links.length > 0

    result

  mount: ->
    return no unless @checkUI()

    @status.avaliable = yes

    @initUI()

    yes

  destroy: ->

    yes

  initAnimation: ->
    tl = new TimelineMax paused: yes
    tl.add 'start'
    tl.set @ui.main, { opacity: 0, display: 'table' }
    tl.set @ui.links, { opacity: 0, x: 0 }

    tl.add 'fadeIn'
    tl.to @ui.main, 0.5, {
      opacity: 1, ease: Power1.easeOut
    }

    delay = 0.0

    @ui.links.each (index, eleLink) =>
      tl.to eleLink, 0.5, { opacity: 1, x: 0, ease: Power1.easeOut }, "fadeIn+=#{delay}"
      delay += 0.1

    tl.add 'appeared'
    tl.set @ui.main, { opacity: 1, display: 'table' }
    tl.to @ui.main, 0.3, {
      opacity: 0, display: 'none', ease: Power1.easeOut
    }

    tl.add 'fadeOut'

    @core.animations.main = tl

  initUI: ->
    @initAnimation()

    # @ui.buttons.on 'click', (event) ->
    #   event.preventDefault() if $(this).hasClass 'disabled'

  show: ->
    return if @status.avaliable is no

    @core.animations.main.tweenFromTo 'start', 'appeared'

  hide: ->
    return if @status.avaliable is no

    @core.animations.main.tweenFromTo 'appeared', 'fadeOut'

window.MainMenu = new MainMenu