import { TweenMax, Power1 } from 'gsap'

# 
# ProjectsShowPage ( 0.1.0.0 )
# 
class ProjectsShowPage extends window.GtCommonComponent
  name: ->
    'projects_show_page'

  initOptions: ->
    # ignore

  fetchUI: ->

    @ui.main = $('body.projects.show')
    @ui.closeButton = @ui.main.find('.close-button')
    @ui.allButton = @ui.main.find('.all-button')

  checkUI: ->
    @fetchUI()

    result =
      @ui.main.length is 1 &&
      @ui.closeButton.length is 1 &&
      @ui.allButton.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initUI: ->
    TweenMax.to [ @ui.allButton, @ui.closeButton ], 0.6, { opacity: 1 }

window.ProjectsShowPage = new ProjectsShowPage