import { TweenMax, Power2 } from 'gsap'

# 
# ProjectsAllPage ( 0.1.0.0 )
# 
class ProjectsAllPage extends window.GtCommonComponent
  name: ->
    'projects_all_page'

  initOptions: ->
    # ignore

  fetchUI: ->
    @core.window = $(window)

    @ui.main = $('body.projects.all')
    @ui.interface = @ui.main.find('.main-interface')
    @ui.images = @ui.main.find('.gallery a')
    @ui.coverInner = @ui.main.find('.cover div')

  checkUI: ->
    @fetchUI()

    result =
      @ui.main.length is 1 &&
      @ui.interface.length is 1 &&
      @ui.images.length > 0 &&
      @ui.coverInner.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initUI: ->
    @initResize()

    TweenMax.to @ui.interface, 2, { opacity: 1, ease: Power2.easeOut, delay: 0.2 }

  initResize: ->
    @core.window.on 'resize', (e) =>
      currentWindowWidth = @core.window.width()

      if currentWindowWidth <= 720
        window.location = @ui.interface.data('sourceUrl')
        return

      newImageSize = 130 * Math.min(currentWindowWidth, 1200) / 1200
      newImageMargin = newImageSize * 0.4 # 1/5

      @ui.coverInner.css
        paddingRight: newImageMargin

      @ui.images.css
        width: newImageSize
        height: newImageSize * 0.75
        marginRight: newImageMargin
        marginBottom: newImageMargin

    @core.window.trigger 'resize'

window.ProjectsAllPage = new ProjectsAllPage