import { TimelineMax, Power1 } from 'gsap'

# 
# ContactPage ( 0.1.0.0 )
# 
class ContactPage extends window.GtCommonComponent
  name: ->
    'about_page'

  initOptions: ->
    @core.animations = {}

  fetchUI: ->

    @ui.main = $('body.feedbacks.new,body.feedbacks.create')
    @ui.messageThanks = $('.message .thanks')
    @ui.messagePhone = $('.message .phone')
    @ui.messageSignature = $('.message .signature')
    @ui.form = $('.simple_form')

  checkUI: ->
    @fetchUI()

    result =
      @ui.main.length is 1 &&
      @ui.messageThanks.length is 1 &&
      @ui.messagePhone.length > 0 &&
      @ui.messageSignature.length is 1 &&
      @ui.form.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initAnimation: ->
    tl = new TimelineMax paused: yes

    tl.add 'start'
    tl.set @ui.messageThanks, { opacity: 0 }
    tl.set @ui.messagePhone, { opacity: 0 }
    tl.set @ui.messageSignature, { opacity: 0 }
    tl.set @ui.form, { opacity: 0 }

    if @ui.main.hasClass('new')
      tl.add 'fadeIn'
      tl.to @ui.messageThanks, 2, { opacity: 1 }, 'fadeIn+=0.0'
      tl.to @ui.messagePhone, 2, { opacity: 1 }, 'fadeIn+=0.3'
      tl.to @ui.messageSignature, 3, { opacity: 0.5 }, 'fadeIn+=0.6'
      tl.to @ui.form, 1.5, { opacity: 1 }, 'fadeIn+=0.8'
    else
      tl.add 'fadeIn'
      tl.to @ui.messageThanks, 1, { opacity: 1 }, 'fadeIn'
      tl.to @ui.messagePhone, 1, { opacity: 1 }, 'fadeIn'
      tl.to @ui.messageSignature, 1, { opacity: 0.5 }, 'fadeIn'
      tl.to @ui.form, 1, { opacity: 1 }, 'fadeIn'

    @core.animations.main = tl

  initUI: ->
    @initAnimation()
    @initButton()

    setTimeout =>
      @core.animations.main.play 0
    , 100

  initButton: ->
    $('a.submit').click (e) =>
      e.preventDefault()
      @ui.form.submit()


window.ContactPage = new ContactPage