# 
# AlbumImages ( 1.1.0.0 )
# 
class AlbumImages extends window.GtCommonComponent
  name: ->
    'album_images'

  route: ->
    /\/(?:projects)/

  initOptions: ->
    @core.cacheImgs = []
    @core.preloadSupport = null

  fetchUI: ->
    @ui.albumImages = $('.album-image')

  checkUI: ->
    @fetchUI()

    result = @ui.albumImages.length > 0

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    @core.preloadSupport.destroy()

    $(window).off '.albumImages'

    yes

  initUI: ->
    @core.preloadSupport =
      new GtListImagesPreloadSupport
        currentPageIndex: PageIndexManager.getCurrentPage()
        perPage: 1
        items: @ui.albumImages
        delay: 120
        breakpoint: =>
          PageControl.isNoNarrowLayout()
        done: (metaData, imageInfo) =>
          @showItem(metaData, imageInfo)
          @core.cacheImgs.push(metaData.ui.img)

    @core.preloadSupport.start()

    @initImgsResize()

  initImgsResize: ->
    $(window).on 'resize.albumImages', (event) =>
      @resizeCurrentImage uiImg for uiImg in @core.cacheImgs

  imageNewClassName: (ratio) ->
    if ratio >= 0.95 then 'portrait-size' else 'landscape-size'

  resizeCurrentImage: (uiImg) ->
    if PageControl.isNoNarrowLayout()
      resizeRatio = 0.75
      aspectRatio = uiImg.data('aspectRatio') || 0.8

      viewportWidth = $(window).width()
      viewportHeight = $(window).height()

      newImageWidth = viewportWidth * ( resizeRatio - 0.05 )
      newImageHeight = newImageWidth * aspectRatio

      if newImageHeight >= viewportHeight - 50
        newImageHeight = viewportHeight * resizeRatio
        newImageWidth = newImageHeight / aspectRatio

      uiImg.css {
        width: "#{newImageWidth}px"
        height: "#{newImageHeight}px"
      }
    else
      uiImg.css {
        width: ''
        height: ''
      }

  showItem: (metaData, imageInfo) ->
    TweenMax.set metaData.ui.item, { opacity: 0 }

    if metaData.imageIsFound
      metaData.ui.img.data 'aspectRatio', imageInfo.aspectRatio

      @resizeCurrentImage(metaData.ui.img)

      metaData.ui.img.attr 'src', metaData.preloadUrl

    TweenMax.to metaData.ui.item, 1, {
      opacity: 1, ease: Power2.easeOut, delay: 0.15
    }

window.AlbumImages = new AlbumImages