import _ from 'lodash'

# 
# MenuActivator ( 0.1.1.0 )
# 
class MenuActivator extends window.GtCommonComponent
  name: ->
    'menu_activator'

  initOptions: ->
    @core.timer = null

  fetchUI: ->
    @ui.main = $('.menu-activator')
    @ui.button = @ui.main.find('a')

  checkUI: ->
    @fetchUI()

    result = @ui.main.length is 1 &&
             @ui.button.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initUI: ->
    @ui.button.on 'click', (event) =>
      event.preventDefault()
      @throttleToggle()

  throttleToggle:
    _.throttle ( -> @toggle() ), 700

  toggle: ->
    if @ui.main.hasClass('opened')
      @ui.main.removeClass('opened').addClass('closed')
      window.PageControl.closeMenu()

    else
      @ui.main.removeClass('closed').addClass('opened')
      window.PageControl.openMenu()


window.MenuActivator = new MenuActivator