# 
# PageControl ( 0.1.1.0 )
# 
class PageControl
  constructor: ->
    @core = {}
    @status = {}
    @initCore()

  initCore: ->
    @core.window = $(window)
    @status.userAgent       = window.navigator.userAgent
    @status.isMobileBrowser = false
    @status.isModernBrowser = false
    @checkBrowserIsMobile()
    @checkBrowserIsModern()

  checkBrowserIsMobile: ->
    result = switch
             when @status.userAgent.indexOf('ANDROID') > -1 then yes
             when @status.userAgent.indexOf('IOS') > -1 then yes
             when @status.userAgent.indexOf('IPHONE') > -1 then yes
             when @status.userAgent.indexOf('MOBILE') > -1 then yes
             else no

    @status.isMobileBrowser = yes if result

  checkBrowserIsModern: ->
    try
      canvas = document.createElement('canvas')
      ctx = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    catch error
      # ignore

    @status.isModernBrowser = yes if ctx?
    canvas = null

  lockPage: ->
    $(document.documentElement).addClass 'locked'

  unlockPage: ->
    $(document.documentElement).removeClass 'locked'

  isNoNarrowLayout: ->
    @core.window.width() > 720

  # changePage: (nextUrl) ->
  #   window.location = nextUrl

  openMenu: ->
    @lockPage()

    window.MainMenu.show()

  closeMenu: ->
    @unlockPage()

    window.MainMenu.hide()

window.PageControl = new PageControl
