import _ from 'lodash'
import TweenMax from 'gsap/TweenMax'

# 
# ProjectImageSlider ( 0.8.6.0 )
# 
class ProjectImageSlider extends window.GtCommonComponent
  name: ->
    'project_image_slider'

  route: ->
    /\/(?:projects)/

  initOptions: ->
    @core.images = []
    @core.autoPlayTimer = null
    @core.pageState = {}

    @status.currentIndex = 0
    @status.totalImageCount = 0
    @status.lastZap = 0
    @status.autoplay = no
    @status.imagePreloading = no
    @status.previousIsNoNarrowLayout = PageControl.isNoNarrowLayout()

  fetchUI: ->
    @ui.window = $(window)
    @ui.main   = $('.project-image-slider')
    @ui.inner  = @ui.main.children('.inner')
    @ui.images = @ui.inner.find('.list .image')

    @ui.preview = @ui.inner.find('.preview')
    @ui.previewLoading = @ui.inner.find('.preview-loading')
    @ui.previewImg = @ui.preview.find('img')

    @ui.nextBtn = @ui.main.find('.next a')
    @ui.prevBtn = @ui.main.find('.prev a')
    @ui.autoPlayBtn = @ui.main.find('.autoplay a')

  checkUI: ->
    @fetchUI()

    result = @ui.main.length is 1 &&
             @ui.inner.length is 1 &&
             @ui.images.length > 0 &&
             @ui.preview.length is 1 &&
             @ui.previewLoading.length is 1 &&
             @ui.previewImg.length is 1 &&
             @ui.nextBtn.length is 1 &&
             @ui.autoPlayBtn.length is 1 &&
             @ui.prevBtn.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUi()

    yes

  destroy: ->
    yes

  resize: (options={}) ->
    item = @core.images[@status.currentIndex]

    @resizePreviewImg item

  next: (options={})->
    return if @status.totalImageCount <= 1

    nextIndex = @status.currentIndex + 1

    if nextIndex >= @status.totalImageCount
      @zapTo 0, options
    else
      @zapTo nextIndex, options

  prev: (options={})->
    return if @status.totalImageCount <= 1

    prevIndex = @status.currentIndex - 1
    
    if prevIndex <= -1
      # ignore
    else
      @zapTo prevIndex, options

  resizePreviewImg: (item) ->
    return if !item?

    resizeRatio = 0.85

    viewportWidth = @ui.window.width()
    viewportHeight = @ui.window.height()

    newImageHeight = viewportHeight * resizeRatio
    newImageWidth = newImageHeight / item.aspectRatio

    if newImageWidth >= viewportWidth - 30
      newImageWidth = viewportWidth * ( resizeRatio * 1.10 )
      newImageHeight = newImageWidth * item.aspectRatio

    @ui.previewImg.attr({ width: newImageWidth, height: newImageHeight })

  getUrlPhotoIndex: ->
    matchPage = window.location.href.match(/\#photo(\d+)/)

    if matchPage?
      parseInt(matchPage[1], 10)
    else
      0

  resetPosition: ->
    @zapTo @getUrlPhotoIndex()

  updatePushState: (imageIndex) ->
    try
      history.pushState @core.pageState, null, "\#photo#{imageIndex}"

  zapTo: (imageIndex, options={}) ->
    return if @status.imagePreloading

    item = @core.images[imageIndex]
    imageIndex = 0 if !item?
    item = @core.images[imageIndex] if imageIndex is 0
    return if !item?

    @updatePushState imageIndex unless options.skipChangeHash

    options.x = options.x || 0

    @status.currentIndex = imageIndex
    PageIndexManager.setCurrentPage index: @status.currentIndex

    changeSpeed = options.changeSpeed || 1.0
    currentTime = +new Date
    lastZapDelta = currentTime - @status.lastZap

    if lastZapDelta <= 800
      changeSpeed = 0.7

    @status.lastZap = currentTime

    @stopAutoPlay()

    @status.imagePreloading = yes

    TweenMax.set @ui.previewLoading, { x: options.x * 1.0, opacity: 0 }
    # TweenMax.to @ui.previewLoading, 0.5, { x: 0, opacity: 1, ease: Power1.easeOut }

    TweenMax.to @ui.preview, 0.2, {
      opacity: 0,
      onComplete: =>
        buffer = new Image
        buffer.onerror = =>
          @loadItemToPreview item, changeSpeed, options
        buffer.onload = =>
          @loadItemToPreview item, changeSpeed, options
        buffer.src = item.imageUrl

        # preload next image
        nextItem = @core.images[imageIndex+1]

        if nextItem?
          buffer2 = new Image
          buffer2.src = nextItem.imageUrl
    }

    @refreshButtonDisplay()

  loadItemToPreview: (item, changeSpeed, options) ->
    @resizePreviewImg item

    # TweenMax.to @ui.previewLoading, 0.2, { opacity: 0, ease: Power1.easeOut }

    TweenMax.set @ui.preview, { x: options.x }
    @ui.previewImg.attr({ src: item.imageUrl })
    TweenMax.to @ui.preview, 0.2, { opacity: 1, ease: Power1.easeOut }
    # TweenMax.to @ui.preview, changeSpeed * 0.9, { x: 0, ease: Power1.easeOut }

    @status.imagePreloading = no

    @setAutoPlay() if @status.autoplay
  
  toggleAutoPlay: (options={}) ->
    if @status.autoplay
      @status.autoplay = no
      @stopAutoPlay options 
    else
      @status.autoplay = yes
      @setAutoPlay options 

    @ui.autoPlayBtn.toggleClass 'paused', @status.autoplay

  stopAutoPlay: ->
    clearTimeout @core.autoPlayTimer

  setAutoPlay: (options={})->
    options.nextTime = options.nextTime || 6000

    @stopAutoPlay()

    @core.autoPlayTimer =
      setTimeout =>
        @next changeSpeed: 1.0 if @status.autoplay
      , options.nextTime

  refreshButtonDisplay: ->
    if @status.currentIndex is 0
      TweenMax.to @ui.prevBtn, 0.6, { opacity: 0 }
    else
      TweenMax.to @ui.prevBtn, 0.6, { opacity: 1 }

    TweenMax.to @ui.autoPlayBtn, 0.6, { opacity: 1 }
    TweenMax.to @ui.nextBtn, 0.6, { opacity: 1 }

  initCaches: ->
    @ui.images.each (index, eleImage) =>
      uiImage = $(eleImage)

      @core.images.push
        index: index
        uiImage: uiImage
        uiImg: uiImage.find('img')
        imageUrl: uiImage.data('thumbnail')
        width: uiImage.data('width')
        height: uiImage.data('height')
        aspectRatio: uiImage.data('aspectRatio')

    @status.totalImageCount = @ui.images.length

  initButtons: ->
    @ui.autoPlayBtn.on 'click', (e) =>
      e.preventDefault()
      @toggleAutoPlay()

    @ui.nextBtn.on 'click', (e) =>
      e.preventDefault()
      @next x: 0

    @ui.prevBtn.on 'click', (e) =>
      e.preventDefault()
      @prev x: 0

  initKeyboardSupport: ->
    $(document).on 'keyup.gallery', (event) =>
      return unless event.target.tagName in [ 'BODY', 'A', 'DIV' ]
      switch event.which
        when 39 then @next x: 0
        when 37 then @prev x: 0
        when 38 then @prev x: 0
        when 40 then @next x: 0
        when 32 then @toggleAutoPlay()

  initResize: ->    
    @resize()
    @ui.window.on 'resize.gallery', => @resize()

  useNoNarrowLayoutMode: ->
    @initResize()
    @initKeyboardSupport()
    @initButtons()
    @initCaches()
    @refreshButtonDisplay()
    @resetPosition()

    $(window).on 'popstate', (e) =>
      @zapTo @getUrlPhotoIndex(), skipChangeHash: yes

    @toggleAutoPlay nextTime: 6200

  useNarrowLayoutMode: ->
    @ui.images.each (index, eleImage) =>
      uiImage = $(eleImage)
      uiImg = uiImage.find('img')
      TweenMax.set uiImg, { opacity: 0 }
      uiImg.on 'load', (e) => TweenMax.to uiImg, 0.5, { opacity: 1 }
      uiImg.attr 'src', uiImage.data('mobi')

  initHammer: ->
    try
      hm = new Hammer(@ui.main.get(0))

      hm.get('swipe').set
        direction: Hammer.DIRECTION_HORIZONTAL
        threshold: 10 * 3

      hm.on 'swipeleft', (e) => @next x: 0 
      hm.on 'swiperight', (e) => @prev x: 0 

    catch e
      # ignore

  initUi: ->

    if PageControl.isNoNarrowLayout()
      @useNoNarrowLayoutMode()
    else
      @useNarrowLayoutMode()

    @ui.window.on 'resize.autoChangeLayout', (e) =>
      if @status.previousIsNoNarrowLayout isnt PageControl.isNoNarrowLayout()
        window.location.reload()

    @initHammer()

window.ProjectImageSlider = new ProjectImageSlider