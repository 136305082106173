# 
# GtDomEventSupport ( 1.0.0 )
# 
class GtDomEventSupport
  constructor: (options={}) ->
    @options   = options
    @component = null
    @mount     = null
    @destroy   = null

    @formatOptions()
    @initComponentStatus()
    @bindEvent()

  formatOptions: ->
    @component = @options.component || null
    @mount     = if @options.mount? then @options.mount else ( -> )

  initComponentStatus: ->
    @component.domStatus =
      loaded: no

  bindEvent: ->
    $(document).ready =>
      if @component.domStatus.loaded isnt yes
        @componentIsLoaded() if @mount()

  componentIsLoaded: ->
    if @component.debug()
      console.info @component.name() + ':component_is_loaded'

    @component.domStatus.loaded = yes

window.GtDomEventSupport = GtDomEventSupport