import { TimelineMax, Power1 } from 'gsap'

# 
# DesignerPage ( 0.1.1.0 )
# 
class DesignerPage extends window.GtCommonComponent
  name: ->
    'designer_page'

  initOptions: ->
    @core.animations = {}

  fetchUI: ->
    @ui.main = $('body.pages.designer')
    @ui.mainInterface = @ui.main.find('.main-interface')
    @ui.photoImg = @ui.main.find('.photo img')

  checkUI: ->
    @fetchUI()

    result =
      @ui.main.length is 1 &&
      @ui.mainInterface.length is 1 &&
      @ui.photoImg.length is 1

    result

  mount: ->
    return no unless @checkUI()

    @initUI()

    yes

  destroy: ->
    yes

  initAnimation: ->
    tl = new TimelineMax paused: yes

    tl.add 'start'
    tl.set @ui.mainInterface, { opacity: 0 }

    tl.add 'fadeIn'
    tl.to @ui.mainInterface, 0.5, { opacity: 1, ease: Power1.easeOut }, "fadeIn"

    @core.animations.main = tl

  initUI: ->
    @initAnimation()

    new GtSimplePreload
      url: @ui.photoImg.attr('src')
      done: (width, height, aspectRatio) =>
        @core.animations.main.play 0

window.DesignerPage = new DesignerPage